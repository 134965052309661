const Contact = () => {
    return(
        <div className={"contact"}>
            <div className={"fieldset"}>
                <label>Mail</label>
                <span><a href="mailto:contact@planteure.com">contact@planteure.com</a></span>
            </div>
            <div className={"fieldset"}>
                <label>Téléphone</label>
                <span>06.21.20.09.68</span>
            </div>
            <div className={"fieldset adresse"}>
                <label>Adresse</label>
                <span>271, rue du haut croth<br/>27310 Bourg-Achard</span>
            </div>
            <a className="bon_commande" href="assets/planteure_bon_commande_2025.ods" target="_blank">BON DE
                COMMANDE</a>
            <a className="bon_commande" href="assets/planteure_bon_commande_2025.pdf" target="_blank">BON DE
                COMMANDE pdf</a>
            <a className="bon_commande" href="assets/Planteure_catalogue_2025.pdf" target="_blank">CATALOGUE</a>

        </div>
    )
}

export default Contact
